import React from 'react';
import cls from 'classnames';
import Image from 'next/image';
import styles from './styles.module.scss';
import { getImage } from '@/services/utils/helper';
import Slider from '@/components/molecules/Slider';
import Link from 'next/link';

const LifeStyle = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('pb-30 ', styles.life_style)}>
			<div className='container product-slider'>
				<div className='text-center ' style={{ textAlign: 'center', marginBottom: 30 }}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<Slider slidesPerView={4.6} spaceBetween={20}
							
							responsive={{
								'0': {
									slidesPerView: 2
								},
								'640': {
									slidesPerView: 2
								},
								'768': {
									slidesPerView: 2,
									spaceBetween: 10
								},
								'800': {
									slidesPerView: 3,
									spaceBetween: 20
								},
								'1024': {
									slidesPerView: 3.2,
									spaceBetween: 20
								},
								'1280': {
									slidesPerView: 3.5,
									spaceBetween: 20
								},
								'1400': {
									slidesPerView: 4.6,
									spaceBetween: 20
								},
								'1600': {
									slidesPerView: 4.6,
									spaceBetween: 20
								},
							}}
				
				>
					{data?.map((d: any, i: number) => {
						return (
							<Link href={children[i]?.link || '/'} key={i}>
								<div className={styles.card}>
									<div style={{ borderRadius: 5 }}>
										<div className={styles.card__image}>
											<Image alt='' src={getImage(d.file)} width={264} height={264} priority unoptimized />
										</div>
									</div>
									<div className={styles.category_card__content}>
										<div className='text-grey900 fw-600 mb-10'>{d?.title}</div>
									</div>
								</div>
							</Link>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default LifeStyle;
