import Button from '@/components/atoms/Button';
import Slider from '@/components/molecules/Slider';
import { getImage, hexToRgbaWithOpacity } from '@/services/utils/helper';
import cls from 'classnames';
import { map } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { MdArrowRight } from 'react-icons/md';
import 'swiper/css/pagination';
import styles from './styles.module.scss';

const Kbeauty = ({ data, label, color_code, sub_title, blockLinks }) => {
	const { children, link } = blockLinks;
	return (
		<div className={cls('py-30 ', styles.k_beauty)} style={{}}>
			<div className='container product-slider'>
				<div
					className={styles.header}
					style={{
						backgroundColor: hexToRgbaWithOpacity(color_code, 0.1)
					}}>
					<div>
						<div className='text-30 text-grey900 fw-700 mb-5'>{label}</div>
						<div className='text-16 text-portage fw-400'>{sub_title}</div>
					</div>
					<Link href={link || ''}>
						<Button
							className='text-18 text-grey900 fw-500'
							style={{
								backgroundColor: color_code,
								borderRadius: 6,
								border: 'none'
							}}
							label={
								<div className='d-flex text-16 items-center gap-10  justify-between'>
									<div>Shop Now</div> <MdArrowRight />
								</div>
							}
							type='primary'
						/>
					</Link>
				</div>

				<div className='my-10  mt-10 items-center'>
					<Slider slidesPerView={5.5} spaceBetween={20}
					responsive={{
						'0': {
							slidesPerView: 2
						},
						'640': {
							slidesPerView: 2
						},
						'768': {
							slidesPerView: 3,
							spaceBetween: 20
						},
						'800': {
							slidesPerView: 3,
							spaceBetween: 20
						},
						'1024': {
							slidesPerView: 2.8,
							spaceBetween: 20
						},
						'1280': {
							slidesPerView: 3.5,
							spaceBetween: 20
						},
						'1400': {
							slidesPerView: 5.5,
							spaceBetween: 20
						},
						'1600': {
							slidesPerView: 5.5,
							spaceBetween: 20
						},
					}}
					
					>
						{map(data, (d: any, i: number) => (
							<Link href={children[i]?.link || '/'} key={i}>
								<div className={styles.card}>
									<div className={styles.card__image}>
										<Image alt='' src={getImage(d.file)} width={220} height={220} priority unoptimized />
									</div>
								</div>
							</Link>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Kbeauty;
