import Slider from '@/components/molecules/Slider';
import generateBlockLink from '@/services/utils/LinkGenerator';
import { checkIsValidColor } from '@/services/utils/helper';
import cls from 'classnames';
import { map } from 'lodash';
import Link from 'next/link';
import FeaturedBrandSingle from './FeaturedBrandSingle';
import styles from './featured-brands.module.scss';

const FeaturedBrands = ({ data }) => {
	const { block, block_data, block_color_code, block_machine_name } = data;
	const { children, link } = generateBlockLink(data);
	return (
		<div className={cls('py-30 ', styles.featured_brands)}>
			<div className='container product-slider'>
				<div className='d-flex justify-space-between items-center mb-60'>
					<h2 className='text-30 text-grey900 fw-600 mb-0'>{block}</h2>

					{block_data?.length > 5.6 && (
						<Link href={link || ''}>
							<div
								className='text-20 text-grey900 fw-700 cursor-pointer hover-text-primary'
								style={{
									color: checkIsValidColor(block_color_code) ? block_color_code : ''
								}}>
								see all
							</div>
						</Link>
					)}
				</div>
				<Slider
					style={{ paddingInlineStart: '10px' }}
					slidesPerView={5.5}
					spaceBetween={20}
					shouldFetchData={block_machine_name}
					SlideComponent={FeaturedBrandSingle}
					responsive={{
						0: {
							slidesPerView: 2,
							spaceBetween: 10
						},
						320: {
							slidesPerView: 2,
							spaceBetween: 10
						},
						480: {
							slidesPerView: 2,
							spaceBetween: 10
						},
						768: {
							slidesPerView: 2.5,
							spaceBetween: 10
						},
						1024: {
							slidesPerView: 2.7,
							spaceBetween: 3
						},
						1280: {
							slidesPerView: 3.5,
							spaceBetween: 10
						},
						1440: {
							slidesPerView: 4,
							spaceBetween: 10
						},
						1400: {
							slidesPerView: 4,
							spaceBetween: 10
						},
						1300: {
							slidesPerView: 3.5,
							spaceBetween: 10
						},
						1600: {
							slidesPerView: 5.5,
							spaceBetween: 10
						}
					}}>
					{map(block_data, (d, i) => (
						<FeaturedBrandSingle
							data={{
								...d,
								link: children[i]?.link
							}}
							key={i}
						/>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default FeaturedBrands;
